import About from "../sections/About";
import Features from "../sections/Features";
import Clients from "../sections/Clients";
import Compatibility from "../sections/Compatibility";
import Versions from "../sections/Versions";
import ContactWsp from '../sections/ContactWsp';

const LandPage = () => {
  return ( 
    <>
    <About/>
    <Features/>
    <Clients/>
    <Compatibility/>
    <Versions/>
    <ContactWsp/>
    </>
  );
}
 
export default LandPage;