import {useRef} from 'react';
import parse from 'html-react-parser';
import useGlobalContext from '../hooks/useGlobalContext';

let idTimer = 0;

const Alert = () => {
  const dlgButton1 = useRef();
  const dlgButton2 = useRef();
  const dlgButton3 = useRef();
  const {alert, setAlert} = useGlobalContext();
  const {type, message, title, buttons, textAlign, secondsToClose, btnText, btnText2, btnText3, btnFocus} = alert;

  if (buttons==="BUTTON_NO_BUTTONS"){
    /*autoclose enabled*/
    clearTimeout(idTimer);
    idTimer = setTimeout(() => {
      setAlert({});
    }, secondsToClose);
  }
  
  const titleClass = (title==="")?"dialog__header hidden":"dialog__header";
  const overlayClass= `dialog-overlay ${buttons}`;
  const dialogClass = `dialog ${type}`;
  const iconClass = `dialog__body-image ${type}`;
  const messageClass = `dialog__body-text ${textAlign}`;
  const buttonClass = `dialog__footer ${buttons}`;

  let button1Class = "dialog__footer-button";
  let button2Class = "dialog__footer-button";
  let button3Class = "dialog__footer-button";

  if (buttons!=="BUTTON_NO_BUTTONS"){
    if (btnFocus === 1){
      button1Class += " default";
      /*dlgButton1.current.focus();*/
    }else if(btnFocus === 2){
      button2Class += " default";
      /*dlgButton2.current.focus();*/
    }else if(btnFocus === 3){
      button3Class += " default";
      /*dlgButton3.current.focus();*/
    }
  }

  const waitPromise = () =>{
    return new Promise((resolve) =>{
      if (buttons === 'BUTTON_NO_BUTTONS'){
        setTimeout(() => resolve('BUTTON_ACEPT_YES'), 2000);
      } else {
        dlgButton1.onclick = () => resolve('BUTTON_ACEPT_YES');
        dlgButton2.onclick = () => resolve('BUTTON_CANCEL_NO');
        dlgButton3.onclick = () => resolve('BUTTON_RETRY');
      }
    });
  }; 

  const promiseResult = async () =>{
    return await waitPromise();
  };

  /*return promiseResult();*/

  return (  
    <div className={overlayClass}>
      <div className={dialogClass}>
        <div className={titleClass}>
          <h3 className="dialog__header-text">{parse(title)}</h3>
        </div>
        <div className="dialog__body">
          <div className={iconClass}></div>
          <p className={messageClass}>{parse(message)}</p>
        </div>
        <div className={buttonClass}>
          <button className={button1Class} ref={dlgButton1}>{btnText}</button>
          <button className={button2Class} ref={dlgButton2}>{btnText2}</button>
          <button className={button3Class} ref={dlgButton3}>{btnText3}</button>
        </div>
      </div>
    </div>
  );
}
 
export default Alert;