import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from "@emailjs/browser";

import { setForm, validate, getJSON } from '../../js/validations.js';
import {DialogConfig, dialogParams} from '../../js/Alert';
import useGlobalContext from '../../hooks/useGlobalContext';

const ContactForm = () => {
  const navigate = useNavigate();
  const { setLoading, firstLoadContactForm, setFirstLoadContactForm, contactFormParams, setAlert } = useGlobalContext();
  const [submitDisabled, setsubmitDisabled] = useState(false);

  useEffect(() => {
    setForm("contact-form");
    window.scroll(0,0);

    if (firstLoadContactForm) {
      setFirstLoadContactForm(false);
      setLoading(true);
      setTimeout(() => setLoading(false), 1500);
    }
  },[]);

  const goMain = () =>{
    document.querySelectorAll(".header__menu-nav").forEach((item) => {
      item.classList.remove('selected');
      if (item.href.includes("/#main")) item.classList.add('selected');
    });

    document.querySelector(".contact").classList.add('unload');
    setTimeout(() => {
      navigate('/#main');
      document.querySelector(".contact").classList.remove('unload');
    }, 800);
  };

  const handleSubmit = async (e) => {
    setsubmitDisabled(true);
    e.preventDefault();
    
    const valResult =  await validate();
    if (valResult.result){
      setAlert(dialogParams("Enviando su mensaje.<br/>Por favor espere", "", DialogConfig.TYPE_INFO));
      try{
        const r = await emailjs.send(process.env.REACT_APP_MAIL_SERVICE_ID, process.env.REACT_APP_MAIL_TEMPLATE_ID, getJSON(),process.env.REACT_APP_MAIL_PUBLIC_KEY);
        if (r.status===200) 
          setAlert(dialogParams("Mensaje enviado.<br/>¡Pronto nos podremos en contacto con Ud!", "", DialogConfig.TYPE_INFO));
        else
          setAlert(dialogParams("Ocurrió un error.<br/>Por favor intente nuevamente.<br/>Si el error persiste puede comunicarse con nosotros via telefónica o WhatsApp.", "", DialogConfig.TYPE_ERROR));
      }catch(err){
        setAlert(dialogParams("Ocurrió un error.<br/>Por favor intente nuevamente.<br/>Si el error persiste puede comunicarse con nosotros via telefónica o WhatsApp.", "", DialogConfig.TYPE_ERROR));
      }
      goMain();
    }else{
      setAlert(dialogParams(valResult.error, "", DialogConfig.TYPE_ERROR));
    };
    setsubmitDisabled(false);
  };

  return (
    <section className="contact">
      <div className="contact-container">
        <form className="contact-form" id="contact-form">

        <h2 className="contact__subtitle">Contáctenos</h2>
        <p className="contact__details">
          Déjenos sus datos y nos pondremos en contacto con Ud.
        </p>
        
        <div className="contact-inputs-container">
          <div className="input-container col-12">
            <input type="text" className="form-control" id="name" placeholder="Nombre" required autoFocus/>
            <label htmlFor="0_name">Nombre</label>
          </div>

          <div className="input-container col-12">
            <input type="text" className="form-control" id="raz_soc" placeholder="Razón Social" required/>
            <label htmlFor="0_raz_soc">Razón Social del CITV</label>
          </div>

          <div className="input-container col-6">
            <input type="number" className="form-control" id="phone" placeholder="Teléfono" required/>
            <label htmlFor="0_phone">Télefono</label>
          </div>

          <div className="input-container col-6">
            <input type="email" className="form-control" id="mail" placeholder="Correo Electrónico" required/>
            <label htmlFor="0_mail">Correo Electrónico</label>
          </div>

          <div className="input-container col-12 row-4">
            <textarea defaultValue={contactFormParams.message} rows="5" className="form-control" id="msg" placeholder="Escriba su consulta" required></textarea>
            <label htmlFor="0_msg">Escriba su consulta</label>
          </div>

          <button
            disabled={submitDisabled}
            className="contact__button-send" 
            onClick={handleSubmit}
          >Enviar
          </button>
          <button
            type="button" 
            className="contact__button-cancel" 
            onClick={goMain}
          >Volver
          </button>
        </div>
      </form>
      </div>
    </section>    
  );
}
 
export default ContactForm;