import logo_sitev from '../../images/logo_sitev.webp';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Header = () => {

  const openMenu = () => {
    document.getElementById("header__hamburger-icon").classList.toggle("active");
    document.getElementById("header__menu-list").classList.toggle("active");
  };

  const closeMenu = (e) => {
    document.getElementById("header__hamburger-icon").classList.remove("active");
    document.getElementById("header__menu-list").classList.remove("active");
    
    document.querySelectorAll(".header__menu-nav").forEach((item) => {
      if (item.innerText===e.target.innerHTML)
        item.classList.add('selected');
      else
        item.classList.remove('selected');
    });

  };

  return (  
    <header className="header">
      <div className="header-container">
        <a className="header__logo-nav" href='https://www.jcsistemasperu.com'>
          <figure className="header__logo-container">
            <img src={logo_sitev} height="53" width="80" className="header__logo" alt="Logo SITEV"></img>
          </figure>
        </a>

        <ul className="header__menu-list" id="header__menu-list">
          <li className="header__menu-item">
            <HashLink 
              to="/#main" 
              className="header__menu-nav selected"              
              onClick={closeMenu}
            >Inicio
            </HashLink>
          </li>
          <li className="header__menu-item">
            <HashLink  
              to="/#features" className="header__menu-nav"
              onClick={closeMenu}
            >Características
            </HashLink>
          </li>
          <li className="header__menu-item">
            <HashLink 
              to="/#clients" className="header__menu-nav"
              onClick={closeMenu}
            >Nuestros Clientes
            </HashLink>
          </li>
          <li className="header__menu-item">
            <HashLink 
              to="/#compatibility" className="header__menu-nav"
              onClick={closeMenu}
            >Equipos compatibles
            </HashLink>
          </li>
          <li className="header__menu-item">
            <HashLink 
              to="/#versions" className="header__menu-nav"
              onClick={closeMenu}
            >Versiones
            </HashLink>
          </li>
          <li className="header__menu-item">
            <Link
              to="/contact" 
              className="header__menu-nav" 
              onClick={closeMenu}
            >Contáctenos
            </Link>
          </li>
        </ul>
      
        <div className="header__hamburger-icon" id="header__hamburger-icon" onClick={openMenu}>
          <span className="header__hamburger-bar"></span>
          <span className="header__hamburger-bar"></span>
          <span className="header__hamburger-bar"></span>
        </div>
      </div>
    </header>
  );
}
 
export default Header;