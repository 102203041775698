const Compatibility = () => {
  return (  
    <section className="compatibility" id="compatibility">
      <div className="compatibility-container">
        <h2 className="compatibility__subtitle">¿Que marcas de equipos puedo usar con SITEV?</h2>
        <p className="compatibility__details">SITEV es compatible con más de 70 modelos de equipos de medición, convirtiéndolo en el <span className="compatibility__details-important">único software multimarca del mercado.</span></p>
        <div className="compatibility-table-container">
          <table className="compatibility__table">
            <thead className="compatibility__table-header">
              <tr>
                <th className="compatibility__table-header" rowSpan="2">LINEAS DE INSPECCIÓN</th>
                <th className="compatibility__table-header" colSpan="10">EQUIPOS DE MEDICIÓN COMPATIBLES CON SITEV</th>
              </tr>
              <tr>
                <th className="compatibility__table-header">LUXÓM.</th>
                <th className="compatibility__table-header">REFLECTÓM.</th>
                <th className="compatibility__table-header">SONÓMETRO</th>
                <th className="compatibility__table-header">ANALIZAD. GASES</th>
                <th className="compatibility__table-header">OPACIM.</th>
                <th className="compatibility__table-header">BANCO SUSPEN.</th>
                <th className="compatibility__table-header">FRENÓM.</th>
                <th className="compatibility__table-header">ALIN. AL PASO</th>
                <th className="compatibility__table-header">CAPTADOR TEMP.</th>
                <th className="compatibility__table-header">CAPTADOR RPM</th>
              </tr>
            </thead>
            <tbody>
              <tr className="compatibility__table-row">
                <th className="compatibility__table-header vertical-header" rowSpan="2">BEISSBARTH</th>
                <td className="compatibility__table-data" rowSpan="2">BEISSBARTH MLD 9<br/><br/>CEM DT-8809A</td>
                <td className="compatibility__table-data" rowSpan="2">CEM DT-8809A</td>
                <td className="compatibility__table-data" rowSpan="2">CEM DT-8852<br/><br/>FLUS ET-958<br/><br/>WENSN WS1361</td>
                <td className="compatibility__table-data" rowSpan="2">AVL HGA 400<br/><br/>AVL DITEST GAS 1000<sup>(4)</sup></td>
                <td className="compatibility__table-data" rowSpan="2">AVL DISMOKE 4000<br/><br/>AVL DISMOKE 480<sup>(4)</sup></td>
                <td className="compatibility__table-data" colSpan="3">BEISSBARTH BD 600<sup>(2)</sup></td>
                <td className="compatibility__table-data">CEM IR DT-8855<br/><br/>Smart Sensor AS882</td>
                <td className="compatibility__table-data">AVL AT7209E</td>
              </tr>
              <tr className="compatibility__table-row">
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data" colSpan="2">BEISSBARTH MB 8100 <sup>(1)</sup></td>
                <td className="compatibility__table-data" colSpan="2">AVL AUX 2000</td>
              </tr>
             
              <tr className="compatibility__table-row row-highlight">
                <th className="compatibility__table-header vertical-header">BOSCH</th>
                <td className="compatibility__table-data">BEISSBARTH MLD 540</td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td  className="compatibility__table-data" colSpan="2">BOSCH BEA350-370</td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
              </tr>
              
              <tr className="compatibility__table-row">
                <th className="compatibility__table-header vertical-header">VTEQ</th>
                <td className="compatibility__table-data">TECNOLUX ALTAIR-COMBI 2350</td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data">CESVA CS-102</td>
                <td className="compatibility__table-data" colSpan="2">CAPELEC 3201-4GASZOPA</td>
                <td className="compatibility__table-data">VTEQ SUSP 3000 <sup>(3)</sup></td>
                <td className="compatibility__table-data">VTEQ BRAK 7000 <sup>(3)</sup></td>
                <td className="compatibility__table-data">VTEQ SLIP 7000 <sup>(3)</sup></td>
                <td className="compatibility__table-data">EXTECH RH401<br/><br/>TPI 337BT</td>
                <td className="compatibility__table-data"></td>
              </tr>

              <tr className="compatibility__table-row row-highlight">
                <th className="compatibility__table-header vertical-header">MAHA</th>
                <td className="compatibility__table-data">MAHA<sup>(5)</sup></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data">MAHA<sup>(5)</sup></td>
                <td className="compatibility__table-data">MAHA<sup>(5)</sup></td>
                <td className="compatibility__table-data">MAHA<sup>(5)</sup></td>
                <td className="compatibility__table-data" colSpan="3">MAHA<sup>(5)</sup></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
              </tr>

              <tr className="compatibility__table-row">
                <th className="compatibility__table-header vertical-header">JEVOL</th>
                <td className="compatibility__table-data">JEVOL JVH-M100<br/><br/>TECNOLUX 2505/L1</td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data">AWA 5636<br/><br/>BENETECH GM1356</td>
                <td className="compatibility__table-data">JEVOL JVE-501</td>
                <td className="compatibility__table-data">JEVOL JVS-600</td>
                <td className="compatibility__table-data" colSpan="3">JEVOL <sup>(6)</sup></td>
                <td className="compatibility__table-data" colSpan="2">BRAINBEE MGT-300 EVO</td>
              </tr>

              <tr className="compatibility__table-row row-highlight">
                <th className="compatibility__table-header vertical-header">COSBER</th>
                <td className="compatibility__table-data">KSB600M</td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data">PCE 322A<br/><br/>COSBER<sup>(9)</sup></td>
                <td className="compatibility__table-data">KWQ5<br/><br/>KWQ5 v4.21</td>
                <td className="compatibility__table-data">KYD6<br/><br/>KYD6 v4.04</td>
                <td className="compatibility__table-data" colSpan="3">COSBER<sup>(9)</sup></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data">VUT-3100<br/><br/>Gasboard 8110<br/><br/>Gasboard 8120</td>
              </tr>

              <tr className="compatibility__table-row">
                <th className="compatibility__table-header vertical-header">HISTRON</th>
                <td className="compatibility__table-data">REG-250</td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data">ANOI 5</td>
                <td className="compatibility__table-data">OPAC 5</td>
                <td className="compatibility__table-data" colSpan="3">HISTRON<sup>(10)</sup></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data">TUR-II</td>
              </tr>

              <tr className="compatibility__table-row row-highlight">
                <th className="compatibility__table-header vertical-header">RYME</th>
                <td className="compatibility__table-data">TECNOLUX MOON 1299</td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data">CESVA SC-20e</td>
                <td className="compatibility__table-data">CAPELEC 3200-GAS</td>
                <td className="compatibility__table-data">CAPELEC 3200-OPA</td>
                <td className="compatibility__table-data">RYME PCR<sup>(11)</sup></td>
                <td className="compatibility__table-data">RYME FRU PCR<sup>(11)</sup></td>
                <td className="compatibility__table-data">RYME AU-NET<sup>(11)</sup></td>
                <td className="compatibility__table-data" colSpan="2">CAPELEC 8530</td>
              </tr>
              <tr className="compatibility__table-row">
                <th className="compatibility__table-header vertical-header">OPUS</th>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data">OPUS 40-P</td>
                <td className="compatibility__table-data">OPUS 100</td>
                <td className="compatibility__table-data" colSpan="3">OPUS<sup>(12)</sup></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
              </tr>
              <tr className="compatibility__table-row row-highlight">
                <th className="compatibility__table-header vertical-header">MÓVIL BEISSBARTH</th>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data">AUTOTEKNIK BM 40010</td>
                <td className="compatibility__table-data">AUTOTEKNIK BM 20200</td>
                <td className="compatibility__table-data">AUTOTEKNIK BM 910</td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
              </tr>
              <tr className="compatibility__table-row">
                <th className="compatibility__table-header vertical-header">MÓVIL CVA</th>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data" colSpan="3">CVA<sup>(8)</sup></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
              </tr>
              <tr className="compatibility__table-row row-highlight">
                <th className="compatibility__table-header vertical-header">MÓVIL DIVEHCO</th>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data">BRAINBEE AGS-688<br/><br/>MAHLE BMU 688</td>
                <td className="compatibility__table-data">BRAINBEE OPA-100<br/><br/>MAHLE DMU 300</td>
                <td className="compatibility__table-data" colSpan="3">DIVEHCO FFX<sup>(7)</sup></td>
                <td className="compatibility__table-data"></td>
                <td className="compatibility__table-data"></td>
              </tr>
            </tbody>
            <tfoot>
              <tr className="compatibility__table-row-footer">
                <td className="compatibility__table-data-footer" colSpan="4"><sup>(1)</sup> Requiere software STL9000</td>
                <td className="compatibility__table-data-footer" colSpan="4"><sup>(5)</sup> Requiere software MAHA EUROSYSTEM v7.20</td>
                <td className="compatibility__table-data-footer" colSpan="3"><sup>(9)</sup> Requiere software de COSBER</td>
              </tr>
              <tr className="compatibility__table-row-footer">
                <td className="compatibility__table-data-footer" colSpan="4"><sup>(2)</sup> Requiere software BnetTool TL v2.52b - SL v2.30 + Dongle</td>
                <td className="compatibility__table-data-footer" colSpan="4"><sup>(6)</sup> Requiere software TSRSeries de JEVOL</td>
                <td className="compatibility__table-data-footer" colSpan="3"><sup>(10)</sup> Requiere software de HISTRON</td>
              </tr>
              <tr className="compatibility__table-row-footer">
                <td className="compatibility__table-data-footer" colSpan="4"><sup>(3)</sup> Requiere software VTEQWin v2.51 o superior + Dongle</td>
                <td className="compatibility__table-data-footer" colSpan="4"><sup>(7)</sup> Requiere software de DIVEHCO</td>
                <td className="compatibility__table-data-footer" colSpan="3"><sup>(11)</sup> Requiere software de RYME</td>
              </tr>
              <tr className="compatibility__table-row-footer">
                <td className="compatibility__table-data-footer" colSpan="4"><sup>(4)</sup> Requiere software de AVL DITEST DSS + Dongle</td>
                <td className="compatibility__table-data-footer" colSpan="4"><sup>(8)</sup> Requiere software de CVA</td>
                <td className="compatibility__table-data-footer" colSpan="3"><sup>(12)</sup> Requiere software de OPUS</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </section>

  );
}
 
export default Compatibility;