import ico_wsp from '../../images/ico-wsp.webp';
import useGlobalContext from '../../hooks/useGlobalContext';

const ContactWsp = () => {

  const { getWhatsAppLink } = useGlobalContext();

  return (  
    <i className="contact-whatsApp-container">
      <a 
        href={getWhatsAppLink()}
        className="contact-wsp-link" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img src={ico_wsp} className="contact-whatsApp-icon" alt="up-arrow"/>
      </a>
    </i>
  );
}
 
export default ContactWsp;