import {useRef} from 'react';
import useGlobalContext from '../../hooks/useGlobalContext';
import {useNavigate} from 'react-router';
import clientInfo from '../../json/clients.json';

const Clients = () => {
  const {setContactFormParams} = useGlobalContext();
  const clientInfoContainer = useRef(null);
  const navigate = useNavigate();
  
  const showInfo = (e) => {
    let item;
  
    if (e.target.tagName==="IMG")
      item = e.target.parentElement;
    else
      item = e.target;
    
    const name = item.getAttribute("alias");
    const info = item.getAttribute("clientInfo");
    if (!info) return;

    clientInfoContainer.current.innerHTML = "";
    clientInfoContainer.current.innerHTML = name + "</br>" + info;
    clientInfoContainer.current.style.top = item.offsetTop - 40 + "px";
    clientInfoContainer.current.style.left = item.offsetLeft + "px";
  };

  const goContact = () => {
    setContactFormParams ({message:'Deseo conocer más sobre SITEV y sus planes de licenciamiento'});
    navigate('/contact');
  };

  const getTotalLines = () =>{
    let tlines = 0;
    for (let i = 0; i < clientInfo.length; i++) {
      tlines+= parseInt(clientInfo[i].lines);
    }
    return tlines;
  };

  return (
    <section className="clients" id="clients">
      <div className="clients-container">
        <div className="clients-container2">
          <h2 className="clients__subtitle">Nuestros Clientes</h2>
          <p className="clients__details">
            SITEV gestiona {getTotalLines()} líneas de inspección en diversos Centros de Inspección Técnica Vehicular a nivel nacional.
            <br/>
            ¡El 25% de Centros de Inspección Técnica del Perú usa SITEV! <sup>(1)</sup>
          </p>
          <p className="clients__details">
            A continuación algunos de nuestros clientes:
          </p>

          <p className="clients__details-disclaimer">
            <sup>(1)</sup>Estadística calculada a Diciembre 2022
          </p>

          <div className="clients-gallery-container" id="clients-gallery-container">
            { 
              clientInfo?.map(row => (
                <figure className="clients__gallery-image-container" key={Math.floor(Math.random() * 10000)} clientinfo={row.lines + " lineas con SITEV en " + row.location} alias={ row.name + (row.alias ? " ("+row.alias+")" : "") + (row.tag ? "("+ row.tag + ")" : "") } onMouseOver={showInfo}>  
                  <img className="clients__gallery-image" width="100" height="119" src={row.logo} alt={row.alias} loading="lazy"/>
                </figure>
              ))
            }
            <div 
              ref={clientInfoContainer}
              className="clients__info-container" id="clients__info-container"></div>
            </div>

          <button className="clients__button-contact" id="clients__button-contact" onClick={goContact}>
            Deseo conocer más sobre SITEV<br/>y sus planes de licenciamiento
          </button>
        </div>
      </div>
    </section>
  )
}
 
export default Clients;