/*crea variables globales o funciones globales*/
import React, { useState, createContext } from "react";
import axios from 'axios';    

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {

  const wspLink ="https://wa.me/51984119920?text=Hola!%20Quisiera%20saber%20m%C3%A1s%20informaci%C3%B3n%20del%20software";

  const [loading, setLoading] = useState(false);
  const [firstLoadContactForm, setFirstLoadContactForm] = useState(true);
  const [contactFormParams, setContactFormParams] = useState({});
  const [alert, setAlert] = useState({});

  const fetchData = async (url) => {
    try{
      const options = {
        method: 'GET',
        url: url
      };
      const {data} = await axios(options);
      return data;

    }catch(error){
      console.log(url, error.message);
    }
  };

  const saveClient = async (formData) => {
    try{
      const form = new FormData();
      
      for (const key in formData){
        form.append (key, formData[key]);
      }
  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: form,
        url: "jcsistemasperu.com/saveclient"
      };
      
      const {data} = await axios(options);
      return data;

    }catch(error){
      console.log(formData, error.message);
    }
  };

  const getWhatsAppLink = () => (wspLink);

  return (
    <GlobalContext.Provider
      value={{
        fetchData,
        saveClient,
        getWhatsAppLink,
        loading, 
        setLoading,
        firstLoadContactForm, 
        setFirstLoadContactForm,
        contactFormParams, 
        setContactFormParams,
        alert,
        setAlert
      }}      
    >
      {children}
    </GlobalContext.Provider>
  )
};