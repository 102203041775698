import {Routes, Route} from 'react-router-dom';
import {GlobalProvider} from './context/GlobalContext';
import MainLayout from './components/layouts/MainLayout';
import LandPage from './components/pages/LandPage';
import ContactForm from './components/pages/ContactForm';
import ErrorPage from './components/pages/ErrorPage';

function App() {
  
  return (
      <GlobalProvider>
        <Routes>
          <Route exact path="/" element={<MainLayout/>}>
            <Route index element={<LandPage/>}/>
            <Route path='/contact' element ={<ContactForm/>} />
            <Route path='/contact/:id' element ={<ContactForm/>} />
          </Route>
          <Route path='*' element ={<ErrorPage/>} />
        </Routes>
      </GlobalProvider>

  );
}

export default App;