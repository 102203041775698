import { Outlet } from "react-router-dom";
import Header from "../sections/Header";
import Footer from "../sections/Footer";
import Loader from "../sections/Loader";
import useGlobalContext from '../../hooks/useGlobalContext';
import Alert from "../../utils/Alert";

const MainLayout = () => {
  const { loading, alert } = useGlobalContext();
  
  return (  
    <>
      {loading && <Loader/>}
      {alert?.message && <Alert/>}
      <Header/>
        <Outlet/>
      <Footer/>
    </>
  );
  
}
 
export default MainLayout;