'strict mode';
var gInputs = [];
const gTitle = document.title;
let idTimer;

const setInvalidStyle = (e) => {
  const bgColor = e.parentNode.style.backgroundColor;
  if (bgColor === "red") return;
  e.parentNode.style.backgroundColor = "red";
  clearTimeout(idTimer);
  idTimer = window.setTimeout(()=>{
    e.parentNode.style.backgroundColor = bgColor;
  }, 750);
};
  
export const setForm = (formName) => {
  const INPUTS = document.getElementById(formName).querySelectorAll("input, select, textarea");
  
  /*descarta elementos deshabilitados y otros que se requiera despues*/
  for (const item of INPUTS) {
    if (!item.disabled) gInputs.push(item); 
  }
};

export const validate = async () => {
  for (const item of gInputs) {
    const e = document.getElementById(item.id);
    const type = e.type.toString();
    const value = e.value;
    let msg = e.placeholder;
    const required = e.required;

    if (msg !== "") 
      msg = "Debe completar el campo " + item.placeholder + " con un dato válido";
    else 
      msg = "Debe completar este campo con un dato válido";

    switch (type) {
        case "text": case "textarea": 
          if (!value && required) {
            setInvalidStyle (e);
            e.focus();
            return {result: false, error: msg};
          }else{
            item.value=value.trim();
          }
          break;
        
        case "email":
          const validMail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);       
          if (!validMail && required) {
            setInvalidStyle (e);
            e.focus();
            return {result: false, error: msg};
          }else{
            item.value = value.trim().toLowerCase();
          }
          break;

        case "number":
          if ((!value || isNaN(value)) && required ) {
            setInvalidStyle (e);
            e.focus();
            return {result: false, error: msg};
          }else{
            item.value=value.trim();
          }
          break; 

        case "select-one":
          if (!value || value==="-1"){
            setInvalidStyle (e);
            e.focus();
            return {result: false, error: "Seleccione una opción de la lista"};
          }
          break;

        default:
          break;
      }
    }
    return {result: true, error: ""};
};

export const getJSON = () => {
  let data={};

  for (const item of gInputs){
    const e = document.getElementById(item.id);
    const value = e.value;
    data[item.id]=value;
  }
  return data;
};