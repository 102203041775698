import useGlobalContext from '../../hooks/useGlobalContext';
import parse from 'html-react-parser';
import {useNavigate} from 'react-router';
import versionInfo from '../../json/sitev_versions.json';

const Versions = () => {
  const { setContactFormParams } = useGlobalContext();
  const navigate = useNavigate();

  const spinVersionCard = (e) =>{
    let versionItem;  /*main card container*/
  
    if (e.target.nodeName==="INPUT")
      versionItem = e.target.previousElementSibling.parentElement;      /*en button*/
    else
      versionItem = e.target.parentElement.parentElement.parentElement;     /*en imagen*/
  
    const cardDetails = versionItem.querySelector(".versions-item-container");
    const button = versionItem.querySelector(".versions__show-card-button");
  
    versionItem.classList.toggle('spin');
    cardDetails.classList.toggle('spin');
    button.classList.toggle('spin');
  
    if (button.classList.contains('spin'))
      button.value="Regresar";
    else
      button.value="¿Que incluye esta versión?";
  
    document.documentElement.style.setProperty('--cardContainerHeight', cardDetails.offsetHeight +"px");
    document.documentElement.style.setProperty('--cardContainerWidth', cardDetails.offsetWidth +"px");
  };

  const goContact = (version) => {
    setContactFormParams({message: `Deseo más información de la versión ${version} de SITEV`});
    navigate('/contact');
  };

  return (  
    <section className="versions" id="versions">
      <div className="versions-section-container">

        <h2 className="versions__section-subtitle">¿Ya sabe que versión de SITEV necesita?</h2>
        <p className="versions__section-details">
          Con {versionInfo?.length} versiones disponibles, puede elegir la que más se ajuste a sus necesidades:
        </p>

        <ul className="versions__list">
        {
          versionInfo.map(item =>{
            return (
              <li className="versions__item" key={item.idVersion}>
                <div className="versions-item-container">
                  <figure className="versions__item-image-container">
                    <img src={item.image} 
                       onClick={spinVersionCard}
                       alt={`Versión ${parse(item.version)}`} className="versions__item-image" width="150" height="150"
                    />
                  </figure>
                  <p className="versions__title">{parse(item.version)}</p>
                  <p className="versions__subtitle">{parse(item.info)}</p>
                  <ul className="versions__details">
                  {
                    item.details?.map(itemDetail =>{
                      return(
                        <li className={(itemDetail.limitations===1) ? ("versions__details-item diclaimer"):("versions__details-item")} key={itemDetail.idDetail}>{parse(itemDetail.detail)}</li>
                      )
                    })
                  }
                    <li className="versions__details-item open-contact" 
                      onClick={()=>{goContact(parse(item.version))}}
                    >
                      ¡Quiero esta versión!
                    </li> 
                  </ul>
                </div>
                <input 
                  type="button" 
                  onClick={spinVersionCard}
                  className="versions__show-card-button" 
                  value="¿Que incluye esta versión?"
                />
              </li>
            )
          })
        }
        </ul>
      </div>
    </section>
  );
};

export default Versions;