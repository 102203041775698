import parse from 'html-react-parser';
import FeaturesTable from './FeaturesTable';
import features from '../../json/features.json';

const Features = () => {

  const sectionToggle = (e) => {
    let container = e.target.nextElementSibling;
    container.classList.toggle('hidden');
    if (container.classList.contains("hidden"))
      e.target.innerHTML = "¿Quiere conocer mas caracteristicas de SITEV?<br>Click aquí para ver más";
    else
      e.target.innerHTML = "Mostrando más caracteristicas de SITEV<br>Click aquí para ocultarlas";
  };
  
  return (  
    <section className="features" id="features">
      <div className="features-container">
        <h2 className="features__subtitle">¿Por qué elegir SITEV?</h2>
        <p className="features__details">SITEV es la mejor opción del mercado debido a características únicas que lo destacan:</p>
        <ul className="features__main-list">
          {
          features.map (item => {
            return(
            <li className="features__main-item" key={item.id}>
              <figure className="features__main-item-image-container">
                <img src={item.image} alt={`Logo ${item.feature}`} className="features__main-item-image" width="100" height="100"/>
              </figure>
              <p className="features__main-title">{parse(item.feature)}</p>
              <p className="features__main-subtitle">{parse(item.description)}</p>
              {parse(item.extra)}
            </li>
            )})
          }
        </ul>
        <button className="features__button-table-toggle toggle-trigger" onClick={sectionToggle}>
          ¿Quiere conocer todas las características de SITEV?
          <br/>
          Click aquí para ver más
        </button>

        <div className="features-table-container hidden">
          <FeaturesTable/>          
        </div>
      </div>
    </section>
  )
}

export default Features;