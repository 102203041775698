'strict mode';

export const DialogConfig = Object.freeze({
  TYPE_INFO: 'TYPE_INFO',
  TYPE_ERROR: 'TYPE_ERROR',
  TYPE_QUESTION: 'TYPE_QUESTION',
  TYPE_DENIED: 'TYPE_DENIED',
  TYPE_ALERT: 'TYPE_ALERT',
  BUTTON_ACEPT: 'BUTTON_ACEPT',
  BUTTON_ACEPTCANCEL: 'BUTTON_ACEPTCANCEL',
  BUTTON_ACEPTCANCEL_DEFAULT_CANCEL: 'BUTTON_ACEPTCANCEL_DEFAULT_CANCEL',
  BUTTON_YESNO: 'BUTTON_YESNO',
  BUTTON_YESNO_DEFAULT_NO: 'BUTTON_YESNO_DEFAULT_NO',
  BUTTON_ACEPTCANCELRETRY: 'BUTTON_ACEPTCANCELRETRY',
  BUTTON_ACEPTCANCELRETRY_DEFAULT_CANCEL: 'BUTTON_ACEPTCANCELRETRY_DEFAULT_CANCEL',
  BUTTON_ACEPTCANCELRETRY_DEFAULT_RETRY: 'BUTTON_ACEPTCANCELRETRY_DEFAULT_RETRY',
  BUTTON_YESNORETRY: 'BUTTON_YESNORETRY',
  BUTTON_YESNORETRY_DEFAULT_NO: 'BUTTON_YESNORETRY_DEFAULT_NO',
  BUTTON_YESNORETRY_DEFAULT_RETRY: 'BUTTON_YESNORETRY_DEFAULT_RETRY',
  BUTTON_NO_BUTTONS: 'BUTTON_NO_BUTTONS',
  RESULT_ACEPT_YES: 'RESULT_ACEPT_YES',
  RESULT_CANCEL_NO: 'RESULT_CANCEL_NO',
  RESULT_RETRY: 'RESULT_RETRY',
  TEXT_CENTER: "TEXT_CENTER",
  TEXT_LEFT: "TEXT_LEFT",
  TEXT_RIGHT: "TEXT_RIGHT"
});

export const dialogParams = (message, title="", type = DialogConfig.TYPE_INFO, buttons = DialogConfig.BUTTON_NO_BUTTONS, textAlign = DialogConfig.TEXT_CENTER, secondsToClose = 2000) => {
  let btnText ="";
  let btnText2 ="";
  let btnText3 ="";
  let btnFocus = 1;
  
  switch (buttons){
    case DialogConfig.ACEPT:
      btnText ="Aceptar";
      break;

    case DialogConfig.ACEPTCANCEL: 
      btnText ="Aceptar";
      btnText2 ="Cancelar";
      break;
  
    case DialogConfig.ACEPTCANCEL_DEFAULT_CANCEL:
      btnText ="Aceptar";
      btnText2 ="Cancelar";
      btnFocus = 2;
      break;
    
    case DialogConfig.YESNO:
      btnText ="Si";
      btnText2 ="No";
      break;

    case DialogConfig.YESNO_DEFAULT_NO:
      btnText ="Si";
      btnText2 ="No";
      btnFocus = 2;
      break;

    case DialogConfig.ACEPTCANCELRETRY:
      btnText ="Aceptar";
      btnText2 ="Cancelar";
      btnText3 ="Reintentar";
      break;
    
    case DialogConfig.ACEPTCANCELRETRY_DEFAULT_CANCEL:
      btnText ="Aceptar";
      btnText2 ="Cancelar";
      btnText3 ="Reintentar";
      btnFocus = 2;
      break;
    
    case DialogConfig.ACEPTCANCELRETRY_DEFAULT_RETRY:
      btnText ="Aceptar";
      btnText2 ="Cancelar";
      btnText3 ="Reintentar";                        
      btnFocus = 3;
      break;
    
    case DialogConfig.YESNORETRY:
      btnText ="Si";
      btnText2 ="No";
      btnText3 ="Reintentar";
      break;
    
    case DialogConfig.YESNORETRY_DEFAULT_NO:
      btnText ="Si";
      btnText2 ="No";
      btnText3 ="Reintentar";
      btnFocus = 2;
      break;
    
    case DialogConfig.YESNORETRY_DEFAULT_RETRY:
      btnText ="Si";
      btnText2 ="No";
      btnText3 ="Reintentar";
      btnFocus = 3;
      break;

    case DialogConfig.NO_BUTTONS:
      break;
  
    default:
      break;
  }
  
  return ({
    type,
    message, 
    title, 
    buttons, 
    textAlign,
    secondsToClose,
    btnText,
    btnText2,
    btnText3,
    btnFocus
  });
};