import {useNavigate} from 'react-router-dom';
import { useEffect } from 'react';
import Footer from '../sections/Footer';
import logo_sitev from '../../images/logo_sitev3.png';

const ErrorPage = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0,0);
  },[]);

  return (
  <>
    <section className="error-page">
      <div className="error-page-container">
        <figure className="error-page-image-container">
          <img src={logo_sitev} className="error-page__image" alt="Logo SITEV"/>
        </figure>
        <h2 className="error-page__subtitle">La página que busca no existe</h2>
        <p className="error-page__details">
          Puede regresar a la página <button className="error-page__go-back" onClick={() => navigate(-1)}>anterior</button> o ir a la página de <button className="error-page__go-main" onClick={() => navigate("/")}>inicio</button>.
        </p>
      </div>
    </section>    
    <Footer/>
  </>
  );

}
 
export default ErrorPage;