import logo_sitev from '../../images/logo_sitev.webp';
import logo_about from '../../images/s_WSP1.webp';

const About = () => {

  window.addEventListener("load", () =>{
    document.querySelector(".main-image-container").classList.add('loaded');
  });

  return (
    <main className="main" id="main">
      <div className="main-container">
        <h1 className="main__title">Sistema Integrado de Inspección Técnica Vehicular</h1>
        <figure className="main-image-container">
          <img src={logo_sitev} width="400" height="267" className="main__image" alt="Logo SITEV"/>
        </figure>
        <h2 className="main__subtitle">Software Informático y de Comunicaciones para Centros de Inspección Técnica Vehicular</h2>
      </div>
      
      <div className="main-container2">
        <div className="main-container3">
          <div className="main-container-info">
            <h2 className="main__subtitle-info">¿Que es SITEV?</h2>
            <p className="main__details">
              Es un software que le permite controlar de manera integral, todo el proceso de inspección técnica vehicular y 
              está desarrollado de acuerdo a los requerimientos y normativas vigentes del Ministerio de Transportes y Comunicaciones - MTC.
              <br/>
              <br/>
              SITEV es además, una herramienta de gestión, administración y toma de decisiones para su CITV.
            </p>
          </div>
          <figure className="main-image-details-container">
            <img className="main__image-details" height="280" width="275" src={logo_about} alt="SITEV es ideal para su CITV"/>
          </figure>
        </div>
      </div>
    </main>
  );
}
 
export default About;