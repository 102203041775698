const Loader = () => {

  return (
    <div className="loaderOverlay" id="loaderOverlay">
      <div className="loader" id="loader">
        <div className="dot white"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
}
 
export default Loader;