import parse from 'html-react-parser';
import featureInfo from '../../json/features_table.json';

const FeaturesTable = () => {
  const colInfo = [
    {colId:'features#id', fieldname:'id', class:'features__table-header table-hidden-col', classtd:'features__table-data table-hidden-col', title:"Código"},
    {colId:'features#feature', fieldname:'feature', class: 'features__table-header', classtd:'features__table-data', title:'Características'},
    {colId:'features#description', fieldname:'description', class:'features__table-header', classtd:'features__table-data', title:"Detalles"}
  ];

  const getGridSelectedId = (e) =>{
    const td = e.target;
    const rowIndex = td.parentElement.rowIndex;
    if (rowIndex===0) return;
  
    try{
      const grid = td.parentElement.parentElement.parentElement;
      const status = grid.getAttribute("status");
      const toggle = grid.getAttribute("toggled");
      if (status ==="disabled" || (status ==="enabled" && toggle ==="1")) return;
  
      const idSel =  grid.rows[rowIndex].cells[0].innerHTML;
      /*const dataIndex = grid.id.split("#")[0];*/
      
      return idSel;
  
    }catch (err){
      console.log(err);
    }
  };

  return (  
    <table 
      className="features__table" 
      id="features__table"
      onClick={getGridSelectedId}
    >
      <thead>
        <tr>
          {
          colInfo.map(col =>(
            <th scope="col" key={col.colId} className={col.class}>{col.title}</th>
          ))
          }
        </tr>
      </thead>
      <tbody id="tFeatures">
        {
          featureInfo.map(row =>{
          return (
            <tr key={Math.random()*100} className="features__table-row">
            {
            colInfo.map(col => (
              <td key={Math.random()*100} className={col.classtd}>{ parse(row[col.fieldname].toString()) }</td>
              ))
            }
            </tr>
          )})
        }
        </tbody>
    </table>
  );
};
 
export default FeaturesTable;